import styled from '@emotion/styled'

import { TEST_IDS } from '@components/city-page/test/constants'
import { colorTextWhite } from '@components/style-helpers/global-variables'
import Logo from '@microcomponents/icons/logo'

import useFooterRules from './useFooterRules'

export default function Footer() {
  const getCurrentYr = () => new Date().getFullYear()
  const footerWarningMessages = useFooterRules()

  return (
    <Container>
      <Content>
        <Section>
          <Link href="/">
            <Logo fill="#fff" />
          </Link>
          <Copy>Copyright © 2014-{getCurrentYr()} Eaze Technologies, Inc. All rights reserved.</Copy>
          {footerWarningMessages &&
            footerWarningMessages.map((warningObj, index) => (
              <Copy key={index} data-test={`warningMsg-${index}`}>
                {warningObj.message}
              </Copy>
            ))}
        </Section>
        <Section>
          <Link href="/signup">Sign Up</Link>
          <Link href="/login">Log In</Link>
          <Link href="https://help.eaze.com/" target="_blank" rel="noopener noreferrer">
            Support
          </Link>
          <Link href="/contact">Contact Us</Link>
        </Section>
        <Section>
          <Link href="/about">About Us</Link>
          <Link href="/blog">Blog</Link>
          <Link href="/press">Press</Link>
          <Link href="/careers">Careers</Link>
        </Section>
        <Section>
          <Link data-e2eid={TEST_IDS.CITY_PAGE_FOOTER_LINK} href="/weed-delivery">
            Delivery Locations
          </Link>
          <Link href="https://drive.eaze.com">Become a Driver</Link>
          <Link href="/licensed-retailers">Licensed Retailers</Link>
          <Link href="/referral-program-rules">Referral Program Rules</Link>
        </Section>
        <Section>
          <Link href="/terms-of-service">Terms of Service</Link>
          <Link href="/privacy-policy">Privacy Policy</Link>
          <Link href="/cookie-manager">Do Not Sell My Info</Link>
        </Section>
      </Content>
    </Container>
  )
}

const cols = '5'

const Container = styled.footer`
  background-color: #353034;
  color: ${colorTextWhite};
  padding: 5rem 5rem 2rem;

  @media (max-width: 767px) {
    padding: 2rem;
  }
`

const Content = styled.div`
  max-width: 91rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(${cols}, 1fr);
  grid-column-gap: 4rem;
  align-items: start;

  & a,
  & a:link,
  & a:visited,
  & a:active {
    color: ${colorTextWhite};
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @media (max-width: 767px) {
    margin: 0 0 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Link = styled.a`
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 1.2rem;
  color: ${colorTextWhite};
  text-decoration: none;
  margin-bottom: 2rem;

  @media (max-width: 767px) {
    margin-bottom: 1rem;
  }
`

const Copy = styled.p`
  flex: 1.5 0;
  font-family: 'Proxima', Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 100;
  margin: 2rem 0 0;

  @media (max-width: 767px) {
    margin: 1.5rem 0 0;
  }
`
